import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import questionIcon from "../assets/question.svg"
import { Container, Row, Col } from "reactstrap"
import styles from "../pages/css/index.module.css"

export default ({data}) => {
  const content = data.craft.content[0];

  return (
    <div>
      <Header/>
        <div className={styles.heroInterior}>
          <Container>
            <Row>
              <Col xs="12">
                <div className={styles.faqContent}>
                  <h1>Frequently Asked Questions</h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className={styles.faqCard}>
                  {content.faqs.map((faq, index) => 
                    <div className={styles.faqs} key={index}>
                      <div className={styles.faqQuestion}><img src={questionIcon} alt="quesiton icon"/>{faq.question}</div>
                      <div className={styles.faqAnswer} dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      <Footer/>
    </div>
  )
}

export const query = graphql`
  query FAQPage {
    craft {
      content: entries(siteId: "4", slug: "faqs") {
        ... on craft_faqs_faqs_Entry {
          id
          faqs {
            ... on craft_faqs_faqs_BlockType {
              question
              answer
            }
          }
        }
      }
    }
  }
`;